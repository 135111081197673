import React, { useEffect, useState } from 'react';
import banner_promo from './Cereales.png';

const tienda = () => {
  window.location.href = "https://pronalce.com/tienda";
};

const PromoPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const lastShown = localStorage.getItem('lastPopupTime');
    const now = new Date().getTime();

    // Verifica si han pasado más de 6 horas
    if (!lastShown || now - lastShown > 6 * 60 * 60 * 1000) {
      setShowPopup(true); // Muestra el pop-up si han pasado más de 6 horas
      localStorage.setItem('lastPopupTime', now);
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    showPopup && (
      <div style={popupStyles.overlay}>
        <div style={popupStyles.popup}>
          <button onClick={closePopup} style={popupStyles.closeButton}>×</button>
          <img src={banner_promo} alt="Promoción" style={popupStyles.image} />
          <div style={popupStyles.content}>
            <button onClick={tienda} style={popupStyles.ctaButton}>¡Ir a la tienda!</button>
          </div>
        </div>
      </div>
    )
  );
};

// Estilos del pop-up
const popupStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Fondo oscuro semitransparente
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Asegura que esté encima de todo
  },
  popup: {
    position: 'relative',
    width: '90%',
    maxWidth: '900px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    overflow: 'hidden', // Para que la imagen se ajuste bien al pop-up
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: 'white', // Blanco para mayor visibilidad sobre la imagen
    zIndex: 1000, // Asegura que esté sobre la imagen
  },
  image: {
    width: '100%',
    height: 'auto',
    display: 'block', // Asegura que la imagen ocupe todo el espacio horizontal
  },
  content: {
    display: 'flex',
    justifyContent: 'center', // Centra el botón horizontalmente
    padding: '20px', // Añade espacio para que el botón no quede pegado a la imagen
  },
  ctaButton: {
    padding: '10px 20px',
    backgroundColor: '#0e76a8',
    color: 'white',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.2s ease',
  },
};

export default PromoPopup;
