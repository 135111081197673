import React, { useState, useEffect } from 'react';
import './CookiePopup.css';  // Importamos el CSS
import platita from "../../design/ico/Planta_Pronalce2.png";


const CookiePopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Verificar si ya se ha aceptado el popup de cookies
    const acceptedCookies = localStorage.getItem('cookiesAccepted');
    if (!acceptedCookies) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    // Guardar preferencia en localStorage y ocultar el popup
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  const handleClosePopup = () => {
    // Cierra el popup sin guardar la aceptación
    setIsVisible(false);
  };

  if (!isVisible) {
    return null; // No renderizar nada si el popup no es visible
  }

  return (
    <div className="cookie-popup">
      <button className="cookie-popup-close" onClick={handleClosePopup}>
        &times;
      </button>
     
      <p>
        <img src={platita} alt="Logo PRonalce" className="cookie-popup-logo" />
        Utilizamos cookies para mejorar tu experiencia en nuestro sitio. Para más detalles, consulta nuestra{' '}
        <a href="/cookie-policy">política de cookies</a>
      </p>
      <button className='cookie-popup-accept' onClick={handleAcceptCookies}>Aceptar</button>
    </div>
  );
};

export default CookiePopup;
