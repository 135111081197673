import React from 'react';
import './CookiePolicy.css'; // Asegúrate de tener el archivo CSS para los estilos

const CookiePolicy = () => {
  return (
    <div className="cookie-policy-container">
      <h2>Política de Cookies</h2>
      
      <p className="intro-text">
        Este sitio utiliza cookies para mejorar su experiencia de navegación y personalizar el contenido que ofrecemos.
        Las cookies permiten que el sitio recuerde sus acciones y preferencias (como el inicio de sesión, el idioma y otras configuraciones)
        durante un período de tiempo, para que no tenga que volver a configurarlas cada vez que regrese al sitio.
      </p>

      <h3>¿Por qué usamos cookies?</h3>
      <p>
        Usamos cookies para mejorar la experiencia de navegación en nuestro sitio web, proporcionar funcionalidades esenciales,
        personalizar el contenido y analizar el comportamiento del usuario para mejorar continuamente nuestros servicios.
      </p>

      <h3>Tipos de cookies que utilizamos</h3>
      <ul className="cookie-list">
        <li><strong>Cookies esenciales:</strong> Necesarias para el funcionamiento básico del sitio.</li>
        <li><strong>Cookies de rendimiento:</strong> Recopilan información anónima sobre cómo los usuarios utilizan el sitio.</li>
        <li><strong>Cookies de funcionalidad:</strong> Permiten recordar sus preferencias.</li>
        <li><strong>Cookies de personalización y publicidad:</strong> Utilizadas para personalizar anuncios y medir su efectividad.</li>
      </ul>

      <h3>¿Cómo puede administrar las cookies?</h3>
      <p>
        Usted puede controlar y/o eliminar las cookies en cualquier momento. Puede eliminar todas las cookies que ya están en su dispositivo 
        y configurar la mayoría de los navegadores para evitar que se instalen. Sin embargo, algunos servicios o funcionalidades 
        podrían no funcionar correctamente.
      </p>

      <h3>Cookies de terceros</h3>
      <p>
        Utilizamos cookies de terceros, como Google Analytics. Consulte las políticas de estos proveedores para obtener más información:
      </p>
      <ul className="third-party-list">
        <li><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Analytics</a></li>
      </ul>

      <h3>Cambios en nuestra Política de Cookies</h3>
      <p>
        Nos reservamos el derecho de realizar modificaciones a esta Política de Cookies en cualquier momento. Cualquier cambio será publicado 
        en esta página.
      </p>

      <h3>Contacto</h3>
      <p>
        Si tiene alguna pregunta sobre esta Política de Cookies, puede ponerse en contacto con nosotros a través de nuestro correo e soporte 
        <a href="mailto:soporte@pronalce.com.co"> soporte@pronalce.com.co</a>
      </p>
    </div>
  );
};

export default CookiePolicy;
