import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Loading from './widget/Loading/Loading';
import Exportaciones from './components/pages/exportaciones/Exportaciones';
import Industria from './components/pages/industria/Industria';
import Maquila from './components/pages/maquila/maquila';
import Body from './components/pages/body/Body';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Bebidas from './components/pages/Bebidas/bebidas';
import Hojuela from './components/pages/Hojuelas/hojuela';
import Harinas from './components/pages/Harinas/harinas';
import Granolas from './components/pages/Granolas/granolas';
import Mezclaslistas from './components/pages/Mezclaslistas/mezclaslistas';
import Nosotros from './components/pages/Nosotros/nosotros';
import Error404 from './Error/404/error404';
import Politicas from './components/pages/Politicas/politicas';
import TrabajaConNostros from './components/pages/TrabajaConNosotros/TrabajaConNosotros';
import RIT_PDF from './components/pages/RIT/Pdf_run';
import CookiePolicy from './components/cookies/CookiePolicy';
import CookiePopup from './components/cookies/CookiePopup';

const TiendaRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://pronalce.myshopify.com/';
  }, []);

  return null; // Renderiza nada ya que es una redirección
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading /> // Muestra el componente de carga mientras se está cargando
      ) : (
        <Router>
          <ScrollToTop />
          <Header />
          <CookiePopup /> {/* Coloca el CookiePopup aquí para que se muestre en todas las rutas */}
          <Routes>
            <Route exact path="/" element={<Body />} />
            <Route path="/Industria" element={<Industria />} />
            <Route path="/Exportaciones" element={<Exportaciones />} />
            <Route path="/Maquila" element={<Maquila />} />
            <Route path="/Bebidas" element={<Bebidas />} />
            <Route path="/Hojuela" element={<Hojuela />} />
            <Route path="/Harinas" element={<Harinas />} />
            <Route path="/Granolas" element={<Granolas />} />
            <Route path="/Mezclas_listas" element={<Mezclaslistas />} />
            <Route path="/Nosotros" element={<Nosotros />} />
            <Route path="/Politicas" element={<Politicas />} />
            <Route path="/Tienda" element={<TiendaRedirect />} />
            <Route path="/TrabajaConNosotros" element={<TrabajaConNostros />} />
            <Route path="/pdf_2024_Pronalce_RIT" element={<RIT_PDF />} />
            <Route path="*" element={<Error404 />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
          </Routes>
          <Footer />
        </Router>
      )}
    </div>
  );
}

export default App;
